import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {adminAuthData, AdminAuthService} from "../../auth/admin.auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public isLogin = false;
  public adminAuthData: adminAuthData;

  constructor(
    private adminAuthService: AdminAuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.adminAuthService.adminAuthData
      .subscribe(
        (adminAuthData) => {
          if (adminAuthData) {
            this.adminAuthData = adminAuthData;
            this.isLogin = true;
          } else {
            this.isLogin = false;
          }
        }
      );
  }
  public onLogout(){
    this.adminAuthService.removeAdminAuthData();
    this.router.navigateByUrl('/login').then();
  }

}
