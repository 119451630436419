<!--<nz-layout>-->
<!--  <nz-header>-->
<!--    <app-header></app-header>-->
<!--  </nz-header>-->
<!--  <nz-layout>-->
<!--    <nz-sider nzWidth="200px" nzTheme="light" nzCollapsible nzBreakpoint="lg" [nzCollapsedWidth]="0">-->
<!--      <ul nz-menu nzMode="inline" class="sider-menu">-->
<!--        <li nz-submenu nzOpen nzIcon="user" nzTitle="subnav 1">-->
<!--          <ul>-->
<!--            <li nz-menu-item nzSelected>option1</li>-->
<!--            <li nz-menu-item>option2</li>-->
<!--            <li nz-menu-item>option3</li>-->
<!--            <li nz-menu-item>option4</li>-->
<!--          </ul>-->
<!--        </li>-->
<!--        <li nz-submenu nzTitle="subnav 2" nzIcon="laptop">-->
<!--          <ul>-->
<!--            <li nz-menu-item>option5</li>-->
<!--            <li nz-menu-item>option6</li>-->
<!--            <li nz-menu-item>option7</li>-->
<!--            <li nz-menu-item>option8</li>-->
<!--          </ul>-->
<!--        </li>-->
<!--        <li nz-submenu nzTitle="subnav 3" nzIcon="notification">-->
<!--          <ul>-->
<!--            <li nz-menu-item>option9</li>-->
<!--            <li nz-menu-item>option10</li>-->
<!--            <li nz-menu-item>option11</li>-->
<!--            <li nz-menu-item>option12</li>-->
<!--          </ul>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </nz-sider>-->
<!--    <nz-layout class="inner-layout">-->
<!--      <nz-breadcrumb>-->
<!--        <nz-breadcrumb-item>Home</nz-breadcrumb-item>-->
<!--        <nz-breadcrumb-item>List</nz-breadcrumb-item>-->
<!--        <nz-breadcrumb-item>App</nz-breadcrumb-item>-->
<!--      </nz-breadcrumb>-->
<!--      <nz-content>-->
<!--        <router-outlet></router-outlet>-->
<!--      </nz-content>-->
<!--    </nz-layout>-->
<!--  </nz-layout>-->

<!--  <nz-footer>-->
<!--    <app-footer></app-footer>-->
<!--  </nz-footer>-->
<!--</nz-layout>-->

<router-outlet></router-outlet>
