import {Injectable} from '@angular/core';
import {ReplaySubject, Subject} from 'rxjs';

export interface adminAuthData {
  username: string;
  id: string;
  session_expired_on: number;
}

@Injectable({providedIn: 'root'})

export class AdminAuthService {

  constructor() {
  }

  public adminAuthData: Subject<adminAuthData> = new ReplaySubject<adminAuthData>(1);

  public getAdminAuthData() {
    const adminAuthData = JSON.parse(localStorage.getItem('myjob360_admin_auth_data') ? atob(localStorage.getItem('myjob360_admin_auth_data')) : null) as adminAuthData;
    return adminAuthData;
  }


  public setAdminAuthData(adminAuthData: adminAuthData) {
    this.adminAuthData.next(adminAuthData);
    localStorage.setItem('myjob360_admin_auth_data', btoa(JSON.stringify(adminAuthData)));
  }

  public removeAdminAuthData() {
    this.adminAuthData.next(null);
    localStorage.removeItem('myjob360_admin_auth_data');
  }
}
