import {Injectable} from '@angular/core';
import {CanLoad, Route, Router, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';

import {AdminAuthService} from './admin.auth.service';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanLoad {

  constructor(
    private adminAuthService: AdminAuthService,
    private router: Router
  ) {
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise<boolean>((resolve) => {
      const adminAuthData = this.adminAuthService.getAdminAuthData();

      if (adminAuthData === null) {
        resolve(false);
        this.adminAuthService.removeAdminAuthData();
        this.router.navigateByUrl(`/login`).then();
      }


      // Session expired.
      if (moment().unix() > adminAuthData.session_expired_on) {
        resolve(false);
        this.adminAuthService.removeAdminAuthData();
        this.router.navigateByUrl('/login').then();
      } else {
        this.adminAuthService.adminAuthData.next(adminAuthData);
        resolve(true);
      }
    });
  }
}
