<div class="content-div">
  <div nz-row>
    <div nz-col nzSpan="12">
      <img src="assets/images/logo.png" alt="MyJob360">
    </div>
    <div nz-col nzSpan="12" class="d-flex align-items-center justify-content-end">
      <a href="#" (click)="onLogout()">
        <i nz-icon nzType="logout" nzTheme="outline" class="me-1" ></i> Logout
      </a>
    </div>
  </div>
</div>
